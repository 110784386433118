import React from "react";
import { FaLinkedin, FaInstagram, FaTwitter } from "react-icons/fa";

import styles from "../../styles.js";
const size = "2em";
class SocialIcons extends React.Component {
  render() {
    const { linkedinUsername, instagramUsername, twitterUsername } = this.props;
    return (
      <div className="social-icons">
        <a
          href={`https://www.linkedin.com/in/${linkedinUsername}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin size={size} color={styles.darkBlue} />
        </a>
        <a
          href={`https://www.instagram.com/${instagramUsername}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={size} color={styles.darkBlue} />
        </a>
        <a
          href={`https://www.twitter.com/${twitterUsername}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter size={size} color={styles.darkBlue} />
        </a>
      </div>
    );
  }
}

export default SocialIcons;
