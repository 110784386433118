import React from "react";

const CircularIcon = ({ src, alt, opacity }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        borderRadius: "50%",
        width: "150px",

        maxWidth: "99%",
        opacity: opacity ? opacity : 1,
      }}
    />
  );
};

export default CircularIcon;
