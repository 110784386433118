const Footer = () => {
  const mainBackground = '/images/background-main.jpeg';
  const styles = {
    container: {
      // /backgroundImage: `url(${mainBackground})`,
      backgroundColor: '#081E27',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      // height: "100vh",
      height: '100%',
      position: 'relative',
      borderTop: '4px solid #47725E',
      zIndex: 1002
    },
    sticky: {position: 'fixed', bottom: 0, width: '100%', height: 70}
  };

  return (
    <div className="bg-light text-center text-lg-start ">
      <div style={{...styles.container, ...styles.sticky}}>
        <div className="text-center text-light p-3">
          <a className="text-light color-med" href="/">
            Home
          </a>
          <br />© {new Date().getFullYear().toString()} Copyright GratitudeBucket.com
        </div>

        <div
          className="text-center p-3 text-light"
          // style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        ></div>
      </div>
    </div>
  );
};
export default Footer;
