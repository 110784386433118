// create a new functional component that renders a logo in the center of the screen
import React from "react";

function LogoCentered({ message }) {
  return (
    <div className="centered">
      <div
        style={{
          height: 370,
          position: "relative",

          paddingTop: 10,
          marginLeft: "auto",
          marginRight: "auto",
          width: 320,

          borderRadius: 10,
          // opacity: 0.8,
        }}
        // className="border"
      >
        <div
          style={{
            width: 320,
            height: 320,
            // backgroundColor: "white",
            borderRadius: 10,
          }}
        >
          <img
            style={{ width: "100%" }}
            src="/images/single icons/Gratitude Bucket logo v4.png"
          />
        </div>
        <div>{message ? message : ""}</div>
      </div>
    </div>
  );
}

export default LogoCentered;
