//get string for strength
//create a map of strings to components
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { SiBrave } from "react-icons/si";

import {
  FaPaintBrush,
  FaBrain,
  FaHandsHelping,
  FaGratipay,
  FaGlasses,
  FaHotjar,
  FaMountain,
  FaUserCircle,
} from "react-icons/fa";
import {
  GiBookshelf,
  GiInjustice,
  GiElephantHead,
  GiFlame,
  GiOwl,
  GiCardKingHearts,
  GiBowman,
  GiStrong,
  GiBrain,
  GiCarKey,
  GiDove,
  GiSunflower,
  GiThreeFriends,
} from "react-icons/gi";
import { GrGroup, GrOptimize } from "react-icons/gr";
import { RiChatSmile3Fill } from "react-icons/ri";
import { TbMath } from "react-icons/tb";
import styles from "../../../../styles";

import strengthsMap from "../StrengthsMap/index";
const size = "2em";

const strengthsMapv1 = {
  brave: <SiBrave size={size} color={styles.darkBlue} />,
  creative: <FaPaintBrush size={size} color={styles.medBlue} />,
  curious: <FaBrain size={size} color={styles.darkBlue} />,
  fair: <GiInjustice size={size} color={styles.darkBlue} />,
  understanding: <FaHandsHelping color={styles.medBlue} size={size} />, //forgiving in VIA
  grateful: <FaGratipay color={styles.lightBlue} size={size} />,
  honest: <FaHandsHelping color={styles.lightBlue} size={size} />,
  optimist: <GiSunflower size={size} />,
  humble: <GiElephantHead size={size} color={styles.medBlue} />, //humility
  funny: <RiChatSmile3Fill color={styles.lightBlue} size={size} />,
  wise: <GiOwl size={size} color={styles.medBlue} />,
  kind: <GiCardKingHearts color={styles.lightBlue} size={size} />,
  leader: <GiBowman color={styles.darkBlue} size={size} />,
  warm: <FaHotjar color={styles.lightBlue} size={size} />,
  intellectual: <GiBookshelf size={size} />,
  gritty: <GiStrong color={styles.darkBlue} size={size} />,
  discerning: <FaGlasses size={size} />, //perspective
  thoughtful: <GiBrain size={size} />, //prudence
  driven: <GiCarKey color={styles.darkBlue} size={size} />, //self regulated
  charming: <GiThreeFriends size={size} />, //social intelligent
  soulful: <GiDove color={styles.lightBlue} size={size} />, //spiritual
  reliable: <FaMountain color={styles.darkBlue} size={size} />, //teamwork
  passionate: <GiFlame color={styles.lightBlue} size={size} />, //zestful

  //outside of VIA
  smart: <TbMath color={styles.lightBlue} size={size} />,
};
const allStrengths = Object.keys(strengthsMap);
const stateFullStrengths = allStrengths.map((item) => {
  return { name: item, active: false };
});
const StrengthsList = ({ strengths = null, handleParent = () => {} }) => {
  return (
    <Row className="align-items-center">
      {strengths.map((item, i) => {
        return (
          <Col
            key={`${item}${i}`}
            xs="4"
            className={`align-items-center text-center my-1 p-0 color-med-green ${
              item.active ? "border-select " : ""
            }`}
          >
            {strengthsMap[item]}
            <br />
            {item}
          </Col>
        );
      })}
    </Row>
  );
};

export default StrengthsList;
