import {Container as BootstrapContainer, Col, Row, Button as BootstrapButton} from 'reactstrap';
import React, {useState, useEffect, useContext} from 'react';
import styles from '../../styles';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Strengths from './components/Strengths';
import {useForm} from 'react-hook-form';
import {Modal} from 'react-bootstrap';
import './styles.css';
import StrengthsList from './components/StrengthsList/index';
import {useNavigate} from 'react-router-dom';
import CircularIcon from '../../components/CircularIcon';
import {icons, iconsArray} from '../../lib/icons';
import {backgroundStyle} from '../../styles';
import Footer from '../../components/Footer';
import {InfinitySpin} from 'react-loader-spinner';
import {Icon} from '@material-ui/core';
import {UserContext} from '../../context/UserProvider';
import {createGratitude, getBucket, redeemCredit} from '../../lib/serviceCalls';
const sortFunction = (a, b) => {
  if (a.unlocked && !b.unlocked) {
    return -1;
  } else if (!a.unlocked && b.unlocked) {
    return 1;
  } else {
    return 0;
  }
};
const backgroundImg = '/images/background-search-page.png';
const backgroundStyle2 = {
  height: 800,
  backgroundImage: `url(${backgroundImg})`
};
function GratitudeBucket() {
  const {user, refreshUserInfo, userToken} = useContext(UserContext);
  const [response, setresponse] = useState();
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [updated, setUpdated] = useState(1);
  const [gratitudes, setGratitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  //GRATITUDE SUBMIT STUFF
  const [activeStrengths, setActiveStrengths] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm();
  const navigate = useNavigate();

  const goTo = ({path}) => {
    navigate(path);
  };

  const getBucketId = () => window.location.pathname.split('/')[2];

  const onSubmit = async (data, e) => {
    try {
      if (!user || !userToken)
        return navigate(`/login?redirect=gratitude/${getBucketId()}&message=Please login to add to a Gratitude Bucket`);

      if (!data.gratitude) {
        console.log('no gratitude submitted');
        alert('You forgot to write a note!');
        return;
      } //todo alert user?

      console.log(activeStrengths);
      console.log({...data, ...{strengths: activeStrengths}});
      //start with the original response
      const optimisticUpdate = response;

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      today = mm + '/' + dd + '/' + yyyy;
      //const user = localStorage.getItem("user");
      //const username = user.username;
      const gratitude = {
        unlocked: false,
        gratitudeText: data.gratitude,
        from: user.username, //todo from token?
        date: today,
        sender: {
          id: 'objectID',
          username: '', //todo
          img: '' //todo from token?
        },
        strengths: activeStrengths
      };

      optimisticUpdate.gratitudes.unshift(gratitude);
      const update = {
        body: {
          gratitudeBucketId: getBucketId(),
          gratitudeText: data.gratitude,
          strengths: activeStrengths
        }
      };

      setGratitudes(optimisticUpdate.gratitudes);
      console.log('token ', user.token);
      const res = await createGratitude(getBucketId(), data.gratitude, activeStrengths);
      console.log('e.target ', e.target);
      reset(); // reset after form submit, react-hook-form
      console.log('res ', res);
      refreshUserInfo();
      setModalOpen(false);
      //scroll to top
      window.scrollTo(0, 0);
    } catch (error) {}
  };
  const handleParent = (activeStrengthsFromForm) => {
    setActiveStrengths(activeStrengthsFromForm);
  };
  const handleClose = (e) => {
    if (e) e.stopPropagation();
    setIsOpenInfoModal(false);
  };

  // listen for modalOpen to change, if user not logged in, redirect to login
  useEffect(() => {
    // return;
    if (!modalOpen) return;
    if (!userToken) {
      console.log('no token redirecting to login');
      return navigate(`/login?redirect=gratitude/${getBucketId()}&message=Please login to add to a Gratitude Bucket`);
    }
  }, [modalOpen]);

  useEffect(() => {
    const asyncFn = async () => {
      try {
        setLoading(true);
        const res = await getBucket(getBucketId());

        if (!res.gratitudes) res.gratitudes = [];
        if (res && res.gratitudes) console.log('res.gratitudes ', res.gratitudes);
        if (res && res.gratitudes) {
          res.gratitudes.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });
        }

        setresponse(res);
        setGratitudes(res.gratitudes);
        refreshUserInfo();
        setLoading(false);
      } catch (error) {
        console.log('err getting bucket ', error.message);
      }
    };
    asyncFn();
  }, [updated]);

  const unlockNote = async () => {
    try {
      setGratitudes(null);
      setLoading(true);
      const res = await redeemCredit(getBucketId());

      setUpdated(updated + 1);
      setLoading(false);
      refreshUserInfo();
      // window.scrollTo(0, 0);
      handleClose();
    } catch (error) {
      console.log('error unlocked note', error.message);
    }
  };
  useEffect(() => {
    console.log('isOpenInfoModal ', isOpenInfoModal);
  }, [isOpenInfoModal]);

  return (
    <React.Fragment>
      {/* Info Modal */}
      <Modal
        show={isOpenInfoModal}
        onHide={handleClose}
        style={{opacity: 1}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>{/* <Modal.Title className="">Gratitude Bucket </Modal.Title> */}</Modal.Header>
        <Modal.Body style={{padding: 0, margin: 0}}>
          <div className="p-3">
            <h5 className="color-med-green">How to unlock a note of appreciation</h5>
            You must use a Gratitude credit to unlock this note.
          </div>

          <div>
            <h3 className="text-center mx-0 my-3 color-med-green">You have earned</h3>
            <div className="text-center">
              <img style={{width: 120}} src="/images/single icons/heart.png" alt="heart" />
            </div>
            {!user ? <h3 className="text-center mx-0 my-3 color-med-green">0 credits</h3> : ''}
            <div className="text-center mx-0 my-3 color-med-green">
              {user ? (user.credits ? ` ${user.credits} credits` : 0 + ' credits') : ''}
              {user ? (
                ''
              ) : (
                <div>
                  <BootstrapButton
                    style={{
                      backgroundColor: '#008C9E',
                      fontSize: '1.5rem'
                    }}
                    onClick={() => {
                      navigate(
                        `/login?redirect=gratitude/${getBucketId()}&message=Please register to add to a Gratitude Bucket`
                      );
                    }}
                  >
                    You must login first to unlock this note.
                  </BootstrapButton>
                  <div
                    onClick={() => {
                      navigate(
                        `/register?redirect=gratitude/${getBucketId()}&message=Please register to add to a Gratitude Bucket`
                      );
                    }}
                    style={{fontSize: '1 rem'}}
                    role="button"
                    className="mt-3"
                  >
                    Or register here.
                  </div>
                </div>
              )}
            </div>
            <div style={{width: '100%', height: 30}}> </div>
            {user && user.credits ? (
              <div
                onClick={() => {
                  unlockNote();
                }}
                className="text-center mb-3"
              >
                <div
                  className="hover-shadow btn btn-info"
                  role="button"
                  style={{
                    background: 'rgb(33, 163, 163)',
                    color: 'white',
                    fontSize: '1.5rem'
                  }}
                  type="button"
                >
                  Click here to unlock this note
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className="background-dark-blue color-light"
            style={{width: '100%', marginLeft: 0, marginRight: 0, padding: 0, height: 20}}
          ></div>
          <div className="p-3">
            <h5 className="color-med-green">How to earn a credit</h5>
            To earn a credit, you simply have to add a note of appreication to a friend's Gratitude Bucket or create a
            new Gratitude Bucket for someone else.
          </div>
          <div className="d-flex justify-content-around my-4">
            <BootstrapButton
              style={{
                backgroundColor: '#008C9E',
                fontSize: '1.2rem',
                color: 'white',
                margin: '0 10px'
              }}
              onClick={() => {
                goTo({path: '/search'});
              }}
              type="button"
              role="button"
              className="btn btn-info"
            >
              Search For A Gratitude Bucket
            </BootstrapButton>
            <BootstrapButton
              style={{
                backgroundColor: '#008C9E',
                fontSize: '1.2rem',
                color: 'white',
                margin: '0 10px'
              }}
              onClick={() => {
                goTo({path: '/create'});
              }}
              type="button"
              role="button"
              className="btn btn-info"
            >
              Create A Gratitude Bucket
            </BootstrapButton>
          </div>
          <div className="p-3">
            <h5 className="color-med-green">Why? </h5>
            <div>
              This is an experiment to pay-forward kindness. We are trying to see how far we can spread kindness. Your
              note can go a long way!
            </div>
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Info Modal */}
      {/* Gratitude Modal Begin */}
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(!modalOpen)}
        style={{opacity: 1}}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title className=""> */}
          {/* Gratitude Bucket{" "} */}

          {/* </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="full-width-modal-body">
          <form onSubmit={handleSubmit(onSubmit)} style={{minWidth: '100%'}}>
            <label htmlFor="gratitude" className="color-dark">
              <h4 className="color-med">
                Add your note of appreciation <strong>{response ? response.name : ''}</strong>
              </h4>
            </label>
            <textarea
              style={{
                width: '100%',
                fontSize: '1.5rem',
                color: 'grey'
              }}
              className="text-muted"
              rows={5}
              {...register('gratitude')}
              placeholder="I'm thankful for..."
            />
            {/* <label htmlFor="name">Your Name</label>
            <input {...register("name", { required: true })} /> */}
            {errors.lastName && <p>Last name is required.</p>}
            <label htmlFor="strengths" className="color-med text-center">
              <h5 className="color-med">
                Click on a few strengths that you think {response ? response.name : ''} embodies.{' '}
              </h5>
            </label>
            <Strengths handleParent={handleParent} />
            {/* <input {...register("stregths", {})} /> */}
            {/* {errors.age && <p>Please enter number for age.</p>} */}
            <input className="hover-shadow " role="button" style={{background: 'rgb(33, 163, 163)'}} type="submit" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(!modalOpen)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Gratitude Modal */}
      <div
        className="page"
        style={{
          minHeight: '1000px',
          border: '5px solid #81C4A3',
          position: 'relative',

          background: '#81C4A3'
        }}
      >
        <div
          style={{
            ...backgroundStyle.container,
            ...backgroundStyle2,
            ...{borderBottom: '4px solid #47725E', display: 'none'}
          }}
        >
          {/* create a vertically centered div */}
          <div className="centered">
            <div
              style={{
                height: 200,
                position: 'absolute',
                top: 80,
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 380,
                // border: "1px solid red",
                // borderRadius: 10,
                opacity: 1
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            minHeight: '100vh',
            marginTop: 30,
            zIndex: 100,
            position: 'relative',
            width: '90%',
            marginLeft: '5%',
            marginRight: '5%'
          }}
        >
          {response ? (
            <Row
              className="align-items-center"
              style={{
                background: styles.darkBlue,
                background: 'transparent',
                borderRadius: 20,
                height: 'auto', //450,
                // backgroundImage: `linear-gradient(${styles.darkGreen}, ${styles.lightGreen})`,
                // background: `${styles.darkGreen}`,
                // boxShadow: `10px 2px 5px${styles.black}`,
                padding: 15
              }}
            >
              <Col>
                <h1 style={{color: 'white', marginTop: 25, border: 'none'}}>{response.name}</h1>
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: '20%',
                    width: '280px',
                    height: 'auto',
                    maxHeight: '400px',
                    overflow: 'hidden',
                    border: '3px solid white'
                  }}
                >
                  <img
                    style={{width: 280, height: 'auto'}}
                    src={
                      response && response.img && response.img.match(/http/)
                        ? response.img
                        : `/images/single icons/user-icon-${Math.floor(Math.random() * 5) + 1}.png`
                    }
                    alt={response.name}
                  />
                </div>

                {/* <BsBucketFill size={30} color="white" /> */}
                <div
                  style={{
                    width: 120,
                    height: 'auto',
                    // backgroundColor: "white",
                    borderRadius: 10,
                    marginTop: 10,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                  role="button"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <img style={{width: '100%'}} src="/images/single icons/Gratitude Bucket logo just letter.png" />
                </div>
                <div
                  style={{color: 'white', marginTop: 10}}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="hover-shadow "
                  role="button"
                >
                  Add to the Gratitude Bucket
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}

          <Grid container spacing={5} justifyContent="center" marginTop={1} marginBottom={5}>
            {gratitudes && gratitudes.length
              ? response.gratitudes.sort(sortFunction).map((item, i) => {
                  if (item.unlocked) {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        lg={4}
                        key={`${item.from}${i}`}
                        style={{height: 'auto'}}
                        sx={{height: 'auto'}}
                      >
                        <Card
                          sx={{
                            // width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            background: '#FAF9F6',
                            borderRadius: 10,
                            height: 'auto'
                          }}
                        >
                          <CardContent
                            sx={{
                              flexGrow: 1,
                              color: '#343838',
                              height: 'auto',
                              background: '#F4DDAE',
                              paddingBottom: 7
                            }}
                          >
                            {/* <h4
                                className="color-med-green"
                                style={{ opacity: ".8" }}
                              >
                                Note:
                              </h4> */}
                            <div
                              style={{
                                marginBottom: 10,

                                fontSize: 20
                              }}
                              className="color-med-green mt-3"
                            >
                              "{item.gratitudeText}"
                            </div>
                            {item.strengths && item.strengths.length ? (
                              <h5 className="color-med-green mt-4" style={{opacity: '.8'}}>
                                I see these in you:
                              </h5>
                            ) : (
                              ''
                            )}
                            {item.strengths && item.strengths.length ? (
                              <StrengthsList strengths={item.strengths} />
                            ) : null}
                          </CardContent>
                          {/* <CardActions justifyContent="end"> */}
                          <div className="d-flex justify-content-center" style={{width: '100%', textAlign: 'right'}}>
                            <div className="text-center">
                              <div className="mt-2" style={{color: styles.medBlue}}>
                                {item.date ? item.date.split('T')[0] : ''}
                              </div>
                              <img
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: 50
                                }}
                                src={
                                  item.sender && item.sender.img
                                    ? item.sender.img
                                    : iconsArray[Math.floor(Math.random() * 6)]
                                }
                                alt="user icon"
                              />
                              <br />
                              <Button size="small" style={{color: styles.medBlue}}>
                                {item.from}
                              </Button>
                            </div>
                            {/* </CardActions> */}
                          </div>
                        </Card>
                      </Grid>
                    );
                  } else {
                    //item locked
                    return (
                      <Grid item xs={12} sm={6} lg={4} key={`${item.from}${i}`}>
                        <Card
                          sx={{
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            // background: "#FAF9F6",
                            background: '#173740',
                            borderRadius: 10
                          }}
                        >
                          <CardContent sx={{flexGrow: 1, color: '#343838'}}>
                            {/* <GiLockedHeart
                                color={styles.darkBlue}
                                size={size}
                              /> */}
                            <CircularIcon
                              src="/images/single%20icons/lock-icon.png"
                              alt="locked heart icon"
                              opacity={0.9}
                            />
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="h6"
                              marginBottom={0}
                              style={{color: styles.darkBlue}}
                            >
                              This note is locked.
                            </Typography>

                            <Typography
                              onClick={() => {
                                if (!isOpenInfoModal) {
                                  setIsOpenInfoModal(true);
                                }
                              }}
                              gutterBottom
                              variant="subtitle1"
                              component="h6"
                              marginBottom={0}
                              style={{color: styles.medBlue}}
                              role="button"
                            >
                              Click here to unlock it.
                            </Typography>
                          </CardContent>
                          {/* <CardActions justifyContent="end"> */}
                          <div className="d-flex  justify-content-center " style={{width: '100%', textAlign: 'right'}}>
                            <div className="text-center justify-content-center">
                              <img
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: 50
                                }}
                                src={
                                  item.sender && item.sender.img
                                    ? item.sender.img
                                    : iconsArray[Math.floor(Math.random() * 6)]
                                }
                              />
                              <br />
                              <Button size="small">{item.from}</Button>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    );
                  }
                })
              : null}
          </Grid>
        </div>
      </div>
      {loading ? (
        <div style={{width: '100%', textAlign: 'center'}}>
          {/* <PulseLoader loading={true} size={50} color="#00B4CC" /> */}
          <InfinitySpin width="180" color="#343838" />
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default GratitudeBucket;
