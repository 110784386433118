import React from "react";
import CircularIcon from "../../../../components/CircularIcon";
const strengthsMap = {
  brave: <CircularIcon src="/images/single icons/brave.png" alt="brave" />,
  creative: (
    <CircularIcon src="/images/single icons/creative.png" alt="creative" />
  ),
  curious: (
    <CircularIcon src="/images/single icons/curious.png" alt="curious" />
  ),
  fair: <CircularIcon src="/images/single icons/fair.png" alt="fair" />,
  understanding: (
    <CircularIcon
      src="/images/single icons/understanding.png"
      alt="understanding"
    />
  ), //forgiving in VIA
  grateful: (
    <CircularIcon src="/images/single icons/grateful.png" alt="grateful" />
  ),
  honest: <CircularIcon src="/images/single icons/honest.png" alt="honest" />,
  optimist: (
    <CircularIcon src="/images/single icons/optimist.png" alt="optimist" />
  ),
  humble: <CircularIcon src="/images/single icons/humble.png" alt="humble" />, //humility
  funny: <CircularIcon src="/images/single icons/funny.png" alt="funny" />,
  wise: <CircularIcon src="/images/single icons/wise.png" alt="wise" />,
  kind: <CircularIcon src="/images/single icons/kind.png" alt="kind" />,
  leader: <CircularIcon src="/images/single icons/leader.png" alt="leader" />,
  warm: <CircularIcon src="/images/single icons/warm.png" alt="warm" />,
  intellectual: (
    <CircularIcon
      src="/images/single icons/intellectual.png"
      alt="intellectual"
    />
  ),
  gritty: <CircularIcon src="/images/single icons/gritty.png" alt="gritty" />,
  discerning: (
    <CircularIcon src="/images/single icons/discerning.png" alt="discerning" />
  ), //perspective
  thoughtful: (
    <CircularIcon src="/images/single icons/thoughtful.png" alt="thoughtful" />
  ), //prudence
  driven: <CircularIcon src="/images/single icons/driven.png" alt="driven" />, //self regulated
  charming: (
    <CircularIcon src="/images/single icons/charming.png" alt="charming" />
  ), //social intelligent
  soulful: (
    <CircularIcon src="/images/single icons/soulful.png" alt="soulful" />
  ), //spiritual
  reliable: (
    <CircularIcon src="/images/single icons/reliable.png" alt="reliable" />
  ), //teamwork
  passionate: (
    <CircularIcon src="/images/single icons/passionate.png" alt="passion" />
  ), //zestful

  //outside of VIA
  smart: <CircularIcon src="/images/single icons/smart.png" alt="smart" />,
};
export default strengthsMap;
