import React, {useEffect, useState} from 'react';
import {Image} from 'react-bootstrap';
import SocialIcons from '../../../../components/SocialIcons/index';
import userEvent from '@testing-library/user-event';
import UserProfile from '../../index';
import StrengthsCount from '../../../../components/StrengthsCount';
import {getBucket, getWindowSize} from '../../../../lib/serviceCalls';
const backgroundImage = '/images/backgrounds/space background.png';

const desktopStyle = {
  background: {
    width: 1024,
    marginTop: '30px',
    height: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    overflow: 'hidden'

    // height: 600,
  },
  text: {
    width: '380px',
    height: '380px',
    position: 'absolute',
    top: 220,
    // marginTop: "-290px",
    left: 10,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    borderRadius: '50%',
    zIndex: 1,
    border: '40px solid #417A69'
  },
  header: {
    fontSize: 60,
    fontWeight: 'bold',
    zIndex: 11111,

    position: 'absolute',
    top: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '100%',
    color: '#417A69'
  },
  divPadding: {
    backgroundColor: '#081E27',

    marginTop: '-10px',
    width: 1024,

    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    position: 'relative'
  }
};
const mobileStyle = {
  background: {
    ...desktopStyle.background,

    height: 500,
    width: '460px',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    maxWidth: '100%'
  },
  text: {
    width: 250,
    height: 250,
    position: 'absolute',
    top: '30px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    borderRadius: '50%',
    border: '20px solid #417A69',
    zIndex: 1
  },
  divPadding: {
    ...desktopStyle.divPadding,
    height: 'auto',
    backgroundColor: '#081E27',
    marginTop: '-50px',

    position: 'relative',
    width: 800,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 10,

    marginBottom: 100
  }
};
const BackgroundLayout = ({image = backgroundImage, userProfilePic, user}) => {
  const {width} = getWindowSize();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gratitudes, setGratitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [strengthsMap, setStrengthsMap] = useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    // send the name and email to the server or perform other desired actions
  };
  useEffect(() => {
    console.log('user ', user);
    (async () => {
      const bucketID = user.bucket && user.bucket.bucketId ? user.bucket.bucketId : '';
      console.log('bucketID ', bucketID);
      // if (!user || !user.token) {
      //   return navigate("/login?message=Please login to view a Gratitude Bucket");
      // }
      setLoading(true);
      console.log('bucketID ', bucketID);
      //get request here
      if (bucketID)
        try {
          const res = await getBucket(bucketID);
          console.log('res from hitapi', res);
          const _strengthMap = {};
          if (!res.gratitudes) res.gratitudes = [];
          if (res && res.gratitudes) console.log('res.gratitudes ', res.gratitudes);
          if (res && res.gratitudes) {
            res.gratitudes.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.date) - new Date(a.date);
            });

            res.gratitudes.forEach((item) => {
              if (item.strengths && item.strengths.length)
                item.strengths.forEach((strength) => {
                  console.log('item ', item);
                  if (_strengthMap[strength]) {
                    _strengthMap[strength] += 1;
                  } else {
                    _strengthMap[strength] = 1;
                  }
                });
            });
          }
          setStrengthsMap(_strengthMap);
          setGratitudes(res.gratitudes);
          setLoading(false);
        } catch (error) {
          console.log('err getting bucket ', error);
        }
    })();
  }, [user]);

  return (
    <React.Fragment>
      <div style={width > 1020 ? desktopStyle.background : mobileStyle.background}>
        <div
          style={{
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            left: 0,
            zIndex: 1
          }}
        >
          <Image
            src={image}
            fluid
            style={{
              width: '100%',
              top: 0,
              left: 0,
              border: '10px solid #081E27',
              borderRadius: 20
            }}
          />
        </div>

        <div style={width > 1020 ? desktopStyle.text : mobileStyle.text}>
          <img style={{width: '100%', borderRadius: '50%'}} src={userProfilePic} alt="profile" />
        </div>
        <div style={width > 1020 ? desktopStyle.header : mobileStyle.header}>
          {user.bucket && user.bucket.bucketName ? user.bucket.bucketName : ''}
        </div>
      </div>
      <div style={width > 1020 ? desktopStyle.divPadding : mobileStyle.divPadding}>
        <div className="row text-center color-light-green">
          <div className="col-xs-12 col-sm-6 ">
            <div className="mt-4">
              <h3 className="text-center my-3 text-light">You have earned</h3>
              <div className="text-center">
                <img style={{width: 120, height: 120}} src="/images/single icons/heart.png" alt="heart" />
                <h3 className="text-center  my-3 color-light-green">
                  {user ? (user.credits ? ` ${user.credits} credits` : `0 credits`) : 'You must login first. '}
                </h3>
                <div className="my-4 px-3 text-light">
                  You can earn credits by adding appreciation to a Gratitude Bucket. These can be used to unlock notes.{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 ">
            <div className="mt-4">
              <h3 className="text-center mx-0 my-3 text-light">Your Gratitude Bucket</h3>
              <div className="text-center">
                <img
                  style={{width: 120, height: 120}}
                  src="https://gratitudebuckets.s3.us-west-1.amazonaws.com/web-assets/bucket.png"
                  alt="bucket icon"
                />
                <h3 className="text-center mx-0 my-3 color-light-green">
                  {user ? (
                    user.bucket && user.bucket.bucketId ? (
                      ` ${user.bucket.bucketName}`
                    ) : (
                      <div>No bucket yet.</div>
                    )
                  ) : (
                    'You must login first. '
                  )}
                </h3>

                <div className="my-4 text-light">
                  {user.bucket && user.bucket.bucketName ? (
                    <a className="text-light" href={`/gratitude/${user.bucket.bucketId}`} alt="your gratitude bucket">
                      <div>Visit your bucket</div>
                    </a>
                  ) : (
                    <div>
                      You can create your bucket simply by clicking{' '}
                      <a className="color-light-green" href="/create" alt="create a bucket">
                        here
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: '2px solid white',
            height: '2px',
            paddingLeft: 15,
            paddingRight: 15
          }}
          className="mt-4 mb-1"
        ></div>
        {strengthsMap ? <StrengthsCount strengthsMap={strengthsMap} /> : null}
      </div>
      <div style={{width: '100%', height: 200}}></div>
    </React.Fragment>
  );
};

export default BackgroundLayout;
