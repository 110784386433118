import React, {useContext, useState, useEffect} from 'react';
import {FaRegistered} from 'react-icons/fa';
import {UserContext} from '../../context/UserProvider';
import {Container} from 'reactstrap';
import LogoCentered from '../../components/LogoCentered';
import {Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import {InfinitySpin} from 'react-loader-spinner';
const path = '/images/single icons';
const errorStyle = {border: '3px solid red'};
const userIconState = [
  {active: false, path: `${path}/user-icon-1.png`},
  {active: false, path: `${path}/user-icon-2.png`},
  {active: false, path: `${path}/user-icon-3.png`},
  {active: false, path: `${path}/user-icon-4.png`},
  {active: false, path: `${path}/user-icon-5.png`},
  {active: false, path: `${path}/user-icon-6.png`}
];
function Registration() {
  const {user, login, logout, register} = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [profileImage, setProfileImage] = useState(`${path}/user-icon-1.png`);
  const [email, setEmail] = useState('');
  const [userIcons, setUserIcons] = useState(userIconState);
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const message = queryParameters.get('message');
  const redirect = queryParameters.get('redirect');
  // event.preventDefault();
  useEffect(() => {
    if (registered) navigate(`/login?redirect=${redirect}&message=Thank you for registering! Please login.`);
  }, [registered]);

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handleExistingImageClick(icon) {
    setUserIcons(
      userIcons.map((item) => {
        if (icon === item.path) {
          return {...item, active: true};
        } else {
          return {...item, active: false};
        }
      })
    );
    setProfileImage(icon);
  }

  const handleSubmit = async (event) => {
    try {
      setSubmitted(true);
      if ([username, password, profileImage, email].includes('')) {
        alert('Please fill out all fields');
        return;
      }
      setLoading(true);
      await register({username, password, profileImage, email});
      await login(email, password);
      setRegistered(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
      alert(error.message);
    }
  };

  return (
    <Container className="page">
      {/* create a vertically centered div */}
      <LogoCentered />
      {!loading ? (
        <div className="centered" style={{maxWidth: '80%', marginLeft: '10%', marginRight: '10%'}}>
          <h2 style={{color: '#417A69'}}>Register</h2>
          {/* <form onSubmit={handleSubmit}> */}
          {/* <label htmlFor="username">Username:</label>
        <br /> */}
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            placeholder="username"
            onChange={handleUsernameChange}
            style={submitted && !username ? errorStyle : {}}
          />
          {/* <br /> */}
          {/* <label htmlFor="username">Email:</label> */}
          <br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="email"
            value={email}
            onChange={handleEmailChange}
            style={submitted && !email ? errorStyle : {}}
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
          {/* <br />
        <label htmlFor="password">Password:</label> */}
          <br />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="password"
            value={password}
            onChange={handlePasswordChange}
            style={submitted && !password ? errorStyle : {}}
          />
          <br />
          <h4 style={{color: '#417A69'}}>Select an avatar:</h4>
          <br />
          <Row>
            {userIcons.map((icon, index) => (
              <div className="col-4 col-md-2" key={icon.path}>
                <div
                  className={`align-items-center text-center ${icon.active ? 'border-select ' : ''}`}
                  style={{width: '100%'}}
                  onClick={() => handleExistingImageClick(icon.path)}
                >
                  <img src={icon.path} id={`user-icon${index + 1}`} style={{cursor: 'pointer', width: '100%'}} />
                </div>
              </div>
            ))}
          </Row>

          <input
            onClick={() => {
              handleSubmit();
            }}
            style={{background: 'rgb(33, 163, 163)'}}
            type="submit"
            value="Submit"
          />
        </div>
      ) : (
        <InfinitySpin />
      )}
      {/* </form> */}
    </Container>
  );
}

export default Registration;
