import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import {InfinitySpin} from 'react-loader-spinner';
import CardComponent from '../../components/Card/index';
import GroupAdmin from './components/GroupAdmin';
import ShowBanner from '../../components/ShowBanner';
import StrengthsCountBanner from './components/StrengthsCountBanner';
import {groupApi} from '../../lib/serviceCalls';

const GroupPage = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [stats, setStats] = useState(null);
  const [isOpenAdminModal, setIsOpenAdminModal] = useState(false);
  useEffect(() => {
    const asyncFn = async () => {
      const groupID = window.location.pathname.split('/')[2];
      setLoading(true);
      try {
        const data = await groupApi.getById(groupID);
        const groupMembers = data.groups[0].members.map((item) => {
          const card = item.bucketName ? {name: item.bucketName, img: item.bucketImage, id: item._id} : null;
          return card;
        });
        setResults(data.groups[0]);
        setCards(groupMembers ? groupMembers : []);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    asyncFn();
  }, []);
  useEffect(() => {
    const asyncFn = async () => {
      const groupID = window.location.pathname.split('/')[2];
      setLoading(true);
      try {
        const data = await groupApi.getStatsById(groupID);

        setStats(data);

        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    asyncFn();
  }, []);

  const handleOpenAdminModal = () => {
    setIsOpenAdminModal(!isOpenAdminModal);
  };
  return (
    <div style={{background: '#343838'}}>
      {results ? <ShowBanner title={results.name} backgroundImage={results.image}></ShowBanner> : null}
      {stats ? (
        <StrengthsCountBanner
          strengthsCountArray={stats.strengthMostPopular}
          group={{name: results ? results.name : ''}}
        />
      ) : null}
      <Row
        className="align-items-center"
        style={{
          background: 'rgb(129, 196, 163)',
          borderRadius: 20,
          height: 'auto', //450,
          padding: 15
        }}
      >
        <Col>
          {/* for a logo if we add */}
          {false && results ? (
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '20%',
                width: '280px',
                height: 'auto',
                maxHeight: '400px',
                overflow: 'hidden',
                border: '3px solid white'
              }}
            >
              <img
                style={{width: 280, height: 'auto'}}
                src={results && results.image && results.image.match(/http/) ? results.image : ''}
                alt={results.name}
              />
            </div>
          ) : (
            '    '
          )}
          <div
            style={{
              width: 120,
              height: 'auto',
              borderRadius: 10,
              marginTop: 10,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          ></div>
        </Col>
      </Row>
      <div className="pb-5 pt-2 page" style={{background: '#81C4A3', minHeight: 600, paddingBottom: 60}}>
        <div className="row  justify-content-center pb-5 mb-0">
          {cards && cards.length ? (
            cards.map((item, i) => {
              const {name, img, id} = item;
              if (name && img)
                return (
                  <CardComponent
                    key={`${item.name} ${i}`}
                    name={name}
                    img={
                      img && img.match(/http/)
                        ? img
                        : `/images/single icons/user-icon-${Math.floor(Math.random() * 5) + 1}.png`
                    }
                    id={id}
                    path={`/gratitude/${id}`}
                  />
                );
            })
          ) : (
            <Container className="page">
              {/* <PulseLoader loading={true} size={50} color="#00B4CC" /> */}
              {loading ? (
                <InfinitySpin width="200" color="#4fa94d" />
              ) : (
                <div className="h3 color-dark-green">No results found</div>
              )}
            </Container>
          )}
        </div>
      </div>
      <Row>
        <Col onClick={handleOpenAdminModal}>Open </Col>
        <Col>
          <GroupAdmin handleModal={handleOpenAdminModal} isOpenModal={isOpenAdminModal} />
        </Col>
      </Row>
    </div>
  );
};

export default GroupPage;
