import React, {useEffect, useState} from 'react';

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {gratitudeResearch} from './data.js';
import CardGrid from '../../components/CardGrid';
const styles = {
  container: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    // height: "100vh",
    height: 600,
    position: 'relative'
  }
};
const bubbleBackgroundv1 = './images/bubble-background.jpeg';
//const bubbleBackground = "./images/background-main.jpeg";
const bubbleBackground = './images/background-search-page.png';
const kidsBackground = './images/kids-background.jpeg';
const scienceBackground = './images/background%20science%20v2.png';
const payItForwardBackground = './images/pay-it-forward-background.png';
const backgrouondStyle = {backgroundImage: `url(${bubbleBackground})`};
const backgroundStyle2 = {
  height: 600,
  backgroundImage: `url(${kidsBackground})`
};
const backgroundStyle3 = {
  height: 600,
  backgroundImage: `url(${scienceBackground})`
};
const backgroundStyle4 = {
  height: 700,
  backgroundImage: `url(${payItForwardBackground})`
};
const centeredBox = {
  height: 200,
  position: 'absolute',
  top: 180,
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 320,

  borderRadius: 10,
  opacity: 0.8
};

const UpgradeModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    // Automatically open the modal after a short delay (e.g., 2 seconds).
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal isOpen={modalIsOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Server Upgrade</ModalHeader>
      <ModalBody>
        Our servers are currently undergoing an upgrade. Some features may be temporarily unavailable. We apologize for
        any inconvenience and appreciate your patience.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

//create a home pages
//create a list component
const Home = () => {
  console.log('home loading')
  useEffect(() => {
    //show alert dialog
    // alert('We are making some scheduled updates to our servers.  We will be back shortly!');
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="page">
      <div style={{...styles.container, ...backgrouondStyle}}>
        {/* <UpgradeModal /> */}
        {/* create a vertically centered div */}
        <div className="centered">
          <div
            style={{
              height: 200,
              position: 'absolute',
              top: 80,
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 380,
              maxWidth: '100%',
              opacity: 1
            }}
          >
            <div
              style={{
                width: 380,
                height: 'auto',
                // backgroundColor: "white",
                borderRadius: '50%',
                maxWidth: '100%'
                // border: "12px solid #005F6B",
              }}
            >
              <img style={{width: '100%'}} src="./images/single icons/Gratitude Bucket logo v4.png" />
              {/* <div
                style={{ color: "white", fontSize: "20px", marginTop: "-10px" }}
              >
                We grow appreciation.
              </div> */}
            </div>
            {/* <h1 style={{ fontSize: 80, color: "black" }}>Gratitude Bucket</h1> */}
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row mt-5" style={{background: ''}}>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <div
                style={{
                  width: '500px',
                  height: 'auto',
                  maxWidth: '100%'
                }}
                className="mx-auto"
              >
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    borderRadius: 100
                  }}
                  src="./images/PAY-IT-FORWARDENGINEERING.png"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h1 style={{color: '#005F6B', border: '0 solid red'}}>A network built on appreciation.</h1>
              {/* <div className="paragraph">
                "Pay it forward" is a phrase that refers to the concept of doing
                something kind or helpful for someone else, not in expectation
                of receiving something in return, but with the intention of
                inspiring that person to "pay it forward" by doing something
                kind or helpful for someone else. The idea is that the kindness
                or helpfulness can be spread and multiplied, creating a ripple
                effect of positive actions.{" "}
              </div> */}
              <div className="paragraph mt-3">
                At Gratitude Bucket, we are running a large
                <strong> pay-kindness-forward experiment</strong>.
              </div>
              <h5 style={{color: '#005F6B'}} className="mt-3">
                Our Approach
              </h5>
              <div className="paragraph font-weight-bold">
                When you receive a note of appreciation to your Gratitude Bucket, you can not see until you
                pay-it-forward by adding a note of appreciation to someone else's Gratitude Bucket.{' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page mt-5">
        <div style={{...styles.container, ...backgroundStyle3}} className="centered mb-3"></div>
        <div
          className="paragraph text-left margin-auto"
          style={{
            fontSize: '1.2rem',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <strong>Gratitude</strong> is a powerful force that has the ability to transform our relationships and improve
          our mental and physical health. When we express gratitude to others, we strengthen the bonds of our
          relationships and create a sense of positivity and connection.
        </div>
        <div
          className="paragraph text-left my-3"
          style={{
            fontSize: '1.2rem',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {' '}
          At the same time, expressing gratitude has been linked to increased happiness and well-being, as well as lower
          levels of stress and depression. It can even boost the immune system! On the other side of the equation,
          receiving gratitude can have a similarly positive impact. It can increase self-esteem and confidence, improve
          mood and overall well-being, and strengthen relationships. All of this adds up to a virtuous cycle of giving
          and receiving gratitude that has the power to create a ripple effect of positivity in our lives and the lives
          of those around us.
        </div>
        <CardGrid cards={gratitudeResearch} />
      </div>
      <div className="page my-5">
        <div style={{...styles.container, ...backgroundStyle4}} className="centered mb-3"></div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h1 style={{color: '#005F6B', border: '0 solid red'}}>How it works</h1>

            <div className="" style={{fontSize: '1.3rem'}}>
              Create a Gratitude Bucket for someone you care about. They can then start receiving appreciation from
              others.
            </div>
            <div className=" mt-5" style={{fontSize: '1.3rem'}}>
              <h4 style={{color: '#005F6B', border: '0 solid red'}}>Our pay-it-forward approach</h4>
              When you receive a note of appreciation on your Gratitude Bucket, you can not see until you pay-it-forward
              by adding a note of appreciation on someone else's Gratitude Bucket.
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div>
              <img
                style={{
                  width: 400,
                  borderRadius: 20,
                  maxWidth: '90%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}
                src="./images/letter locked text.png"
              />
            </div>
            <div className="my-5">
              <h4 style={{color: '#005F6B', border: '0 solid red'}}>Unlock your note by paying it forward</h4>
            </div>
            <div>
              <img
                style={{
                  width: 400,
                  borderRadius: 20,
                  maxWidth: '90%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}
                src="./images/letter open text.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

//export default Home;
