const styles = {
  black: "#343838",
  darkBlue: "#005F6B",
  medBlue: "#008C9E",
  lightBlue: "#00B4CC",
  lightestBlue: "#00DFFC",
  darkGreen: "#173740",
  lightGreen: "#417A69",
};

export const backgroundStyle = {
  container: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    // height: "100vh",
    height: 600,
    position: "relative",
  },
};
export default styles;
