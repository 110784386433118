//import react
import React, {useState, useEffect, useContext} from 'react';
import {
  groupApi,
  searchBuckets
} from '../../../../lib/serviceCalls';
import {Button, Container, Row, Col, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {Modal} from 'react-bootstrap';
//a functional component that renders the group admin component
const GroupAdmin = ({ group, isOpenModal, handleModal }) => {
  console.log('isOpenModal', isOpenModal);

  //search for a bucket to add to this group
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  //search for a bucket to add to this group
  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await searchBuckets(search);
    setSearchResults(response);
    setLoading(false);
  };

  //add a bucket to this group
  const handleAddBucket = async (bucket) => {
    setLoading(true);
    const response = await groupApi.members.add(group._id, bucket._id);
    if (response) {
      setUserMessage('Bucket added successfully');
    } else {
      setUserMessage('Error adding bucket');
    }
    setLoading(false);
  };

  //remove a bucket from this group
  const handleRemoveBucket = async (bucket) => {
    setLoading(true);
    const response = await groupApi.members.remove(group._id, bucket._id);
    if (response) {
      setUserMessage('Bucket removed successfully');
    } else {
      setUserMessage('Error removing bucket');
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        show={isOpenModal}
        onHide={() => handleModal(!isOpenModal)}
        style={{opacity: 1}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Header closeButton>test</Modal.Header>
          <h2>Group Admin</h2>
          <h3>Search for a bucket to add to this group</h3>
          {/* <form onSubmit={handleSearch}>
        <input type="text" value={search} onChange={(event) => setSearch(event.target.value)} />
        <button type="submit">Search</button>
      </form> */}
          <form onSubmit={handleSearch}>
            <div
              style={{
                width: 440,
                maxWidth: '100%',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
              className="row justify-content-center"
            >
              <div style={{width: 300, marginRight: 5}} className="d-flex flex-row">
                <Input
                  style={{
                    width: 380,
                    marginTop: '10px',
                    marginBottom: 10,
                    marginRight: 5,
                    fontSize: 20
                  }}
                  width={70}
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder="search..."
                />
              </div>
              <div style={{width: 60, marginTop: 14}}>
                <Button type="">go</Button>
              </div>
            </div>
          </form>

          {loading ? <div>Loading...</div> : null}
          {searchResults.length > 0 ? <div>Search results:</div> : null}
          {searchResults.map((bucket) => {
            console.log('bucket', bucket);
            return (
              <div key={bucket._id}>
                {/* display bucket image a */}
                <img src={bucket.img} alt={bucket.name} style={{width: 90, height: 90}} />
                <div>{bucket.name}</div>
                <button onClick={() => handleAddBucket(bucket)}>Add to group</button>
              </div>
            );
          })}
          <Modal.Footer>test</Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default GroupAdmin;
