import {Container as BootstrapContainer, Col, Row} from 'reactstrap';
import React, {cleanUp, useState, useEffect} from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import {useForm} from 'react-hook-form';
import {Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import './styles.css';
import {InfinitySpin} from 'react-loader-spinner';
import LogoCentered from '../../components/LogoCentered/index';

import {redirectThis} from '../../lib/redirect';
import {createBucket} from '../../lib/serviceCalls';
import {set} from 'react-ga';

function CreateBucket() {
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  function resizeImage(file, maxWidth = 500, maxHeight = 500) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let {width, height} = img;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          file.type,
          0.8 // Adjust the image quality (0 to 1)
        );
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  }

  useEffect(() => {
    redirectThis({
      navigate,
      message: 'Please login before you create a Gratitude Bucket.'
    });
  }, []);

  const onSubmit = async (data) => {
    try {
      setErrorMessage('');
      setLoading(true);
      const image = data.picture.item(0);
      const resizedImageBlob = await resizeImage(image, 500, 500);

      const bucket = await createBucket(data.name, data.email, resizedImageBlob);
      setLoading(false);
      navigate(`/gratitude/${bucket.id}`);
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
      setLoading(false);
      //scroll to top
      window.scrollTo(0, 0);
    }
  };

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();

  return (
    <div className="container mb-5 page">
      <LogoCentered />
      <h2 className="color-dark-green" style={{marginTop: -30}}>
        Create a Gratitude Bucket
      </h2>
      {errorMessage ? (
        <div className="alert alert-danger" role="alert">
          <span>There was an issue with creating your bucket:</span>
          <div>
            <span className="fw-bold">{errorMessage}</span>
          </div>
          <div>If the issue persists, please contact support. </div>
        </div>
      ) : null}
      {loading ? <InfinitySpin width="200" color="#4fa94d" /> : null}
      {!loading ? (
        <form onSubmit={handleSubmit(onSubmit)} className="text-dark ">
          <label className="text-dark" htmlFor="name">
            <h4 className="text-dark">Add their name here </h4>
            <h6 className="text-secondary text-center">(Who is this for?)</h6>
          </label>
          <input {...register('name', {required: true})} />
          {errors.lastName && <p>Last name is required.</p>}

          <label className="text-dark" htmlFor="name">
            <h4 className="text-dark text-center">Add their email</h4>
            <h6 className="text-secondary text-center">
              (This is needed for them to claim the Gratitude Bucket and view it. We don't use it for anything else.)
            </h6>
          </label>
          <input {...register('email', {required: true})} />
          {errors.email && <p>An email is required.</p>}

          <label htmlFor="picture">
            <h4 className="text-dark">Upload a picture</h4>
          </label>
          <input {...register('picture', {required: true})} type="file" name="picture" />
          {errors.picture && <p>A picture is required.</p>}
          <input style={{background: 'rgb(33, 163, 163)'}} type="submit" />
        </form>
      ) : (
        ''
      )}
    </div>
  );
}

export default CreateBucket;
