import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import SocialIcons from "../SocialIcons";

const TeamMembers = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} sm={6} md={4} mt="2">
          <Image
            style={{ width: "100%", border: "6px solid #417A69" }}
            src="https://gratitudebuckets.s3.us-west-1.amazonaws.com/images/zack-lensa.jpeg"
            roundedCircle
          />
          <h4 className="color-med-green mt-2">Zack Prager, MAPP</h4>
          <div className="color-med-green my-2">
            Zack is a proud father working to develop a safer, happier internet
            for his daughter. He is the co-founder of{" "}
            <a href="https://mindfullabs.com">Mindful Labs</a>, a lecturer at
            UCSD, and senior developer for the Robbins (Baskin & Robbins) family{" "}
            <a href="https://foodrevolution.org">business</a>.
          </div>
          <div className="color-med-green my-2">
            He works on building user experiences that make people happier and
            healthier in a field called Humane Technology. He studied software
            development at UCLA and Positive Psychology at the University of
            Pennsylvania under Martin Seligman.
          </div>
          <div className="color-med-green my-2">
            Along with running launching apps ( GratitudeBucket.com,
            Ransomly.com, FoodRevolution.org), he often lectures at UC San
            Diego, San Diego State University, and conferences.
          </div>
          <div className="color-med-green my-2">
            His work has been covered by the SF Chronicle, SD Tribune, ABC, CW,
            NBC, Brit+Co, & more, as well as praised by academics.
          </div>
          <div className="color-med-green my-2">
            As a mixed-race developer he believes we should strive for more
            representation in tech.
          </div>
          <SocialIcons
            linkedinUsername="zack-prager"
            instagramUsername="positivelyzack"
            twitterUsername="zackprager"
          />
        </Col>
        <Col xs={12} sm={6} md={4} className="mt-2">
          <Image
            style={{ width: "100%", border: "6px solid #417A69" }}
            src="/images/team/edgar.jpeg"
            roundedCircle
          />
          <h4 className="color-med-green mt-2">Edgar Spickerman</h4>
          <div>Edgar is a first-generation Mexican American developer. </div>
          <div>
            He works as a full-stack developer on several teams and has been a
            TA at the UCSD fullstack bootcamp.{" "}
          </div>
          <SocialIcons
            linkedinUsername="edgar-spickerman"
            // instagramUsername="positivelyzack"
            // twitterUsername="zackprager"
          />
        </Col>
        <Col xs={12} sm={6} md={4} className="mt-2">
          <Image
            style={{ width: "100%", border: "6px solid #417A69" }}
            src=" https://gratitudebuckets.s3.us-west-1.amazonaws.com/web-assets/anthony_profile.jpeg"
            roundedCircle
          />
          <h4 className="color-med-green mt-2">Anthony Jang</h4>
          <div>Anthony is a backend developer with a pet turtle.</div>
          <div>
            He has experience with start-ups that are working to have a positive
            impact on people's health.
          </div>
          <SocialIcons
            linkedinUsername="anthony-yh-jang"
            // instagramUsername="positivelyzack"
            // twitterUsername="zackprager"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMembers;
