import {queryAllByAltText} from '@testing-library/react';
import React, {useEffect, useState} from 'react';

import {Container, Row, Col, Input, Button} from 'reactstrap';
import CardComponent from '../../components/Card/index';
import {useNavigate} from 'react-router-dom';
import {backgroundStyle} from '../../styles';
import {InfinitySpin} from 'react-loader-spinner';
import {searchBuckets} from '../../lib/serviceCalls';
const backgroundImg = './images/background-search-page.png';
const backgroundStyle2 = {
  height: 400,
  backgroundImage: `url(${backgroundImg})`,
  opacity: 0.9
};
const Search = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const goTo = ({path}) => {
    navigate(path);
  };
  const onSubmit = async () => {
    try {
      setLoading(true);
      const data = await searchBuckets(query);
      setResults(data);
      setLoading(false);
    } catch (error) {
      setQuery('');
      console.log(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setQuery('');
    onSubmit();
  }, []);
  return (
    <div className="page mb-0 pb-0">
      <div
        style={{
          ...backgroundStyle.container,
          ...backgroundStyle2,
          ...{borderBottom: '4px solid #47725E'}
        }}
      >
        {/* create a vertically centered div */}
        <div className="centered">
          <div
            style={{
              height: 200,
              position: 'absolute',
              top: 20,
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 320,
              // border: "1px solid red",
              // borderRadius: 10,
              opacity: 1
            }}
          >
            <div
              style={{
                width: 280,
                height: 'auto',
                // backgroundColor: "white",
                borderRadius: 10
              }}
            >
              <img style={{width: '100%'}} src="./images/single icons/Gratitude Bucket logo v4.png" />
              {/* <div
                style={{ color: "white", fontSize: "20px", marginTop: "-10px" }}
              >
                We grow appreciation.
              </div> */}
              <div
                style={{
                  width: 440,
                  maxWidth: '100%',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                className="row justify-content-center"
              >
                <div style={{width: 300, marginRight: 5}} className="d-flex flex-row">
                  <Input
                    style={{
                      width: 360,
                      marginTop: '10px',
                      marginBottom: 10,
                      marginRight: 5,
                      fontSize: 20
                    }}
                    width={70}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    value={query}
                    placeholder="search..."
                  />

                  <div style={{width: 60, marginTop: 14}}>
                    <Button onClick={onSubmit}>go</Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <h1 style={{ fontSize: 80, color: "black" }}>Gratitude Bucket</h1> */}
          </div>
        </div>
      </div>
      {/* <Row>
        <Col>
          <div className="h1">Search Gratitude Buckets</div>
        </Col>
      </Row> */}

      <div
        style={{
          width: 440,
          maxWidth: '100%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        className="row justify-content-center"
      ></div>
      <div className="pb-5 pt-2 page" style={{background: '#81C4A3', minHeight: 600, paddingBottom: 60}}>
        <div className="row  justify-content-center pb-5 mb-0">
          {results && results.length ? (
            results.map((item, i) => {
              const {name, img, id} = item;
              return (
                <CardComponent
                  key={`${item.name} ${i}`}
                  name={name}
                  img={
                    img && img.match(/http/)
                      ? img
                      : `/images/single icons/user-icon-${Math.floor(Math.random() * 5) + 1}.png`
                  }
                  id={id}
                  path={`/gratitude/${id}`}
                />
              );
            })
          ) : (
            <Container className="page">
              {/* <PulseLoader loading={true} size={50} color="#00B4CC" /> */}
              {loading ? (
                <InfinitySpin width="200" color="#4fa94d" />
              ) : (
                <div className="h3 color-dark-green">No results found</div>
              )}
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
