import React, {useContext} from 'react';

//wrapperstyle = {background: 'rgb(129, 196, 163)', marginTop: '-50px', zIndex: 1000}
/*
            [{name:'creativity', count:2}, {name:'kindness, count: 3}]
            */
const StrengthsCountBanner = ({strengthsCountArray, group}) => {
  console.log('strengthsCountArray', strengthsCountArray);
  return (
    <React.Fragment>
      <div
        className="container-fluid "
        style={{background: 'transparent', zIndex: 1000, borderBottom: '3px solid white'}}
      >
        <div className="text-light">
          <h5 className="color-light-green text-light">Top strengths @ {group ? group.name : 'this group'}</h5>
        </div>
        <div className="d-flex flex-wrap justify-content-center text-center color-light-green text-light pb-3 ">
          {strengthsCountArray && strengthsCountArray.length > 0
            ? strengthsCountArray.map((item, index) => {
                return (
                  <div className=" px-1 text-center  justify-content-start">
                    <div
                      style={{
                        width: '120px',
                        margin: 'auto',
                        display: 'block'
                      }}
                    >
                      <img
                        style={{width: '120px', height: '120px'}}
                        src={`/images/single icons/${item.name}.png`}
                        alt={item.name}
                      />
                      <div className="text-center  my-1  color-light-green text-light">
                        {item.name ? item.name.toUpperCase() : ''}
                      </div>
                      <div className="text-center  my-1  color-light-green text-light">
                        <span style={{fontSize: 12}}></span> {item.count}
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StrengthsCountBanner;
