import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styles from "../../styles";
import { useNavigate } from "react-router-dom";
function CardComponent({ name, id, img, path }) {
  const navigate = useNavigate();

  const goTo = ({ path }) => {
    navigate(path);
  };
  return (
    <Card
      style={{
        width: "18rem",
        margin: "5px",
        paddingTop: 10,
        borderRadius: 10,
        height: "auto",
        minHeight: "auto",
      }}
      onClick={() => {
        return path ? goTo({ path }) : null;
      }}
      role="button"
    >
      <Card.Img
        variant="top"
        src={img ? img : "holder.js/100px180"}
        style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
      />
      <Card.Body>
        <Card.Title>{name ? name : ""}</Card.Title>
        <div style={{ color: styles.medBlue }}>Add to this bucket</div>
        {/* <Card.Text>Add to this bucket</Card.Text> */}
        {/* <Button variant="primary">Add to this bucket</Button> */}
      </Card.Body>
    </Card>
  );
}

export default CardComponent;
