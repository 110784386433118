import React, {createContext, useState} from 'react';
import {createUser, userLogin, userProfile} from '../lib/serviceCalls';

export const UserContext = createContext();

const LOGIN_PATH = '/users/login';
const USER_PATH = '/users/userprofile';
const REGISTER_PATH = '/users';

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [attempts, setAttempts] = useState(0);
  if(attempts === 0){
    setAttempts(1);
    console.log('user ', user, 'token ', userToken);
  
  !user && localStorage.getItem('user') && setUser(JSON.parse(localStorage.getItem('user')));
  !userToken && localStorage.getItem('token') && setUserToken(localStorage.getItem('token'));
  }
  console.log('user ', user, 'token ', userToken);
  const login = async (email, password) => {
    try {
      const loginResponse = await userLogin(email, password);
      setUserToken(loginResponse.token);
      localStorage.setItem('token', loginResponse.token);

      const userInfo = await userProfile();
      setUser(userInfo);
      localStorage.setItem('user', JSON.stringify(userInfo));

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(loginResponse);
        }, 100);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      setUser(null);
      setUserToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    } catch (error) {
      console.error(error);
    }
  };

  const register = async ({username, email, password, profileImage, affiliation}) => {
    try {
      const affiliationProcess = affiliation ? affiliation : 'none';
      console.log('img ', profileImage, username, email, password, profileImage);
      await createUser(email, password, username, profileImage, affiliationProcess);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const refreshUserInfo = async () => {
    try {
      const profileResponse = await userProfile();
      setUser(profileResponse);
      localStorage.setItem('user', JSON.stringify(profileResponse));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        refreshUserInfo,
        register,
        userToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
