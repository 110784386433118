import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserProvider";
import LogoCentered from "../../components/LogoCentered/index";
import { Container } from "reactstrap";
import { Navigate } from "react-router-dom";
const LogoutButton = () => {
  const { logout } = useContext(UserContext);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleLogout = () => {
    logout(); //context logout function
    setShouldRedirect(true);
  };
  if (shouldRedirect) {
    return <Navigate to="/home" />;
  }
  return (
    <Container className="page">
      <LogoCentered message="We will miss you while you are gone!" />

      <div onClick={handleLogout} style={{ width: 300 }} className="mx-auto">
        <input
          className="mt-2"
          style={{ background: "rgb(33, 163, 163)" }}
          type="submit"
          value={"Logout"}
        />
      </div>
    </Container>
  );
};

export default LogoutButton;
