//get string for strength
//create a map of strings to components
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { SiBrave } from "react-icons/si";

import {
  FaPaintBrush,
  FaBrain,
  FaHandsHelping,
  FaGratipay,
  FaGlasses,
  FaHotjar,
  FaMountain,
} from "react-icons/fa";
import {
  GiBookshelf,
  GiInjustice,
  GiElephantHead,
  GiElephant,
  GiFlame,
  GiOwl,
  GiCardKingHearts,
  GiBowman,
  GiStrong,
  GiPeaceDove,
  GiBrain,
  GiCarKey,
  GiDove,
  GiSunflower,
  GiThreeFriends,
} from "react-icons/gi";
import { GrGroup, GrOptimize } from "react-icons/gr";
import { RiChatSmile3Fill } from "react-icons/ri";
import { TbMath } from "react-icons/tb";
import styles from "../../../../styles";
const size = "2em";
const CircularIcon = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        borderRadius: "50%",
        width: "150px",

        maxWidth: "99%",
      }}
    />
  );
};
const strengthsMapV1 = {
  brave: <CircularIcon src="./images/single icons/creative.png" alt="brave" />,
  creative: <FaPaintBrush size={size} color={styles.medBlue} />,
  curious: <FaBrain size={size} color={styles.darkBlue} />,
  fair: <GiInjustice size={size} color={styles.darkBlue} />,
  understanding: <FaHandsHelping color={styles.medBlue} size={size} />, //forgiving in VIA
  grateful: <FaGratipay color={styles.lightBlue} size={size} />,
  honest: <FaHandsHelping color={styles.lightBlue} size={size} />,
  optimist: <GiSunflower size={size} />,
  humble: <GiElephantHead size={size} color={styles.medBlue} />, //humility
  funny: <RiChatSmile3Fill color={styles.lightBlue} size={size} />,
  wise: <GiOwl size={size} color={styles.medBlue} />,
  kind: <GiCardKingHearts color={styles.lightBlue} size={size} />,
  leader: <GiBowman color={styles.darkBlue} size={size} />,
  warm: <FaHotjar color={styles.lightBlue} size={size} />,
  intellectual: <GiBookshelf size={size} />,
  gritty: <GiStrong color={styles.darkBlue} size={size} />,
  discerning: <FaGlasses size={size} />, //perspective
  thoughtful: <GiBrain size={size} />, //prudence
  driven: <GiCarKey color={styles.darkBlue} size={size} />, //self regulated
  charming: <GiThreeFriends size={size} />, //social intelligent
  soulful: <GiDove color={styles.lightBlue} size={size} />, //spiritual
  reliable: <FaMountain color={styles.darkBlue} size={size} />, //teamwork
  passionate: <GiFlame color={styles.lightBlue} size={size} />, //zestful

  //outside of VIA
  smart: <TbMath color={styles.lightBlue} size={size} />,
};

const strengthsMap = {
  brave: <CircularIcon src="/images/single icons/brave.png" alt="brave" />,
  creative: (
    <CircularIcon src="/images/single icons/creative.png" alt="creative" />
  ),
  curious: (
    <CircularIcon src="/images/single icons/curious.png" alt="curious" />
  ),
  fair: <CircularIcon src="/images/single icons/fair.png" alt="fair" />,
  understanding: (
    <CircularIcon
      src="/images/single icons/understanding.png"
      alt="understanding"
    />
  ), //forgiving in VIA
  grateful: (
    <CircularIcon src="/images/single icons/grateful.png" alt="grateful" />
  ),
  honest: <CircularIcon src="/images/single icons/honest.png" alt="honest" />,
  optimist: (
    <CircularIcon src="/images/single icons/optimist.png" alt="optimist" />
  ),
  humble: <CircularIcon src="/images/single icons/humble.png" alt="humble" />, //humility
  funny: <CircularIcon src="/images/single icons/funny.png" alt="funny" />,
  wise: <CircularIcon src="/images/single icons/wise.png" alt="wise" />,
  kind: <CircularIcon src="/images/single icons/kind.png" alt="kind" />,
  leader: <CircularIcon src="/images/single icons/leader.png" alt="leader" />,
  warm: <CircularIcon src="/images/single icons/warm.png" alt="warm" />,
  intellectual: (
    <CircularIcon
      src="/images/single icons/intellectual.png"
      alt="intellectual"
    />
  ),
  gritty: <CircularIcon src="/images/single icons/gritty.png" alt="gritty" />,
  discerning: (
    <CircularIcon src="/images/single icons/discerning.png" alt="discerning" />
  ), //perspective
  thoughtful: (
    <CircularIcon src="/images/single icons/thoughtful.png" alt="thoughtful" />
  ), //prudence
  driven: <CircularIcon src="/images/single icons/driven.png" alt="driven" />, //self regulated
  charming: (
    <CircularIcon src="/images/single icons/charming.png" alt="charming" />
  ), //social intelligent
  soulful: (
    <CircularIcon src="/images/single icons/soulful.png" alt="soulful" />
  ), //spiritual
  reliable: (
    <CircularIcon src="/images/single icons/reliable.png" alt="reliable" />
  ), //teamwork
  passionate: (
    <CircularIcon src="/images/single icons/passionate.png" alt="passion" />
  ), //zestful

  //outside of VIA
  smart: <CircularIcon src="/images/single icons/smart.png" alt="smart" />,
};

const allStrengths = Object.keys(strengthsMap);
const stateFullStrengths = allStrengths.map((item) => {
  return { name: item, active: false };
});
const Strengths = ({ strengths = null, handleParent = () => {} }) => {
  const [strengthsToList, setStrengthsToList] = useState(
    strengths ? strengths : stateFullStrengths
  );
  // if (listAll) {
  //   setStrengthsToList(stateFullStrengths);
  // } else {
  //   setStrengthsToList(strengths);
  // }
  const handleClick = (item) => {
    if (strengths) return;
    setStrengthsToList(
      strengthsToList.map((strength) => {
        if (strength.name === item.name) {
          return { name: item.name, active: !strength.active };
        } else {
          return strength;
        }
      })
    );
  };
  useEffect(() => {
    handleParent(
      strengthsToList.filter((item) => item.active).map((item) => item.name)
    );
  }, [strengthsToList]);

  return (
    <Row className="align-items-center ">
      {strengthsToList.map((item, i) => {
        return (
          <Col
            xs="4"
            md="2"
            onClick={() => {
              if (!strengths) handleClick(item);
            }}
            role="button"
            key={`${item}${i}`}
            className={`align-items-center text-center ${
              item.active ? "border-select " : ""
            }`}
          >
            {strengths ? strengthsMap[item] : strengthsMap[item.name]}
            <br />
            {strengths ? item : item.name}
          </Col>
        );
      })}
    </Row>
  );
};

export default Strengths;
