import React, {useContext, useState, useEffect} from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import './styles.css';
import {InfinitySpin} from 'react-loader-spinner';
import LogoCentered from '../../components/LogoCentered/index';
import {redirectThis} from '../../lib/redirect';
import {UserContext} from '../../context/UserProvider';
import {groupApi} from '../../lib/serviceCalls';

function CreateBucket() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  useEffect(() => {
    redirectThis({
      navigate,
      message: 'Please login before you create a Group'
    });
  }, []);
  const onSubmit = async (data) => {
    try {
      console.log('on submit called');
      setLoading(true);
      const image = data.picture.item(0);
      const gratitudeGroup = await groupApi.create(data.name, image);
      setLoading(false);
      navigate(`/group/${gratitudeGroup.groupID}`);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();

  return (
    <div className="container mb-5 page backgroundGreen">
      <LogoCentered />
      <h2 className="color-dark-green" style={{marginTop: -30}}>
        Create a Gratitude Group
      </h2>
      {loading ? <InfinitySpin width="200" color="#4fa94d" /> : null}
      {!loading ? (
        <form onSubmit={handleSubmit(onSubmit)} className="text-dark ">
          <label className="text-dark" htmlFor="name">
            <h4 className="text-dark">Add the group name here </h4>
          </label>
          <input {...register('name', {required: true})} />
          {errors.lastName && <p>Group name is required.</p>}

          <label htmlFor="picture">
            <h4 className="text-dark">Upload a logo</h4>
          </label>
          <input {...register('picture', {required: true})} type="file" name="picture" />
          <input style={{background: 'rgb(33, 163, 163)'}} type="submit" />
        </form>
      ) : (
        ''
      )}
    </div>
  );
}

export default CreateBucket;
