import React, {useState, useEffect} from 'react';

const ImageComponent = ({img}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onload = () => {};
    image.onerror = () => {
      setIsImageBroken(true);
    };
  }, [img]);

  return !isImageBroken && <img src={img} alt="stength image" />;
};

export default ImageComponent;
