import {React} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Home from './pages/Home';
import GratitudeBucket from './pages/GratitudeBucket';
import Footer from './components/Footer';
// import dotEnv from "dotenv";
import CreateBucket from './pages/CreateBucket/index';
import Team from './pages/Team/index';
import Contact from './pages/Contact/index';
import Science from './pages/Science/index';
import Search from './pages/Search';
import UserProfile from './pages/UserProfile';
import NavigationV3 from './components/NavigationV3';
import {UserProvider} from './context/UserProvider';
import Login from './pages/Login';
import Logout from './pages/Logout';
import About from './pages/About';
import 'bootstrap/dist/css/bootstrap.min.css';
import Registration from './pages/Registration/index';
import TeamBuilding from './pages/TeamBuilding/index';
import GroupPage from './pages/GroupPage/index';
import CreateGroup from './pages/CreateGroup/index';

function App() {
  return (
    <UserProvider>
      <div className="App">
        <Router>
          <NavigationV3 />
          {/* */}
          <Routes>
            <Route path="/gratitude/:id" element={<GratitudeBucket />} />
            <Route path="/create" element={<CreateBucket />} />
            <Route path="/search" element={<Search />} />
            <Route path="/user" element={<UserProfile />} />
            <Route path="/team" element={<Team />} />
            <Route path="/group/create" element={<CreateGroup />} />
            <Route path="/group/search" element={<GroupPage />} />
            <Route path="/group/:groupID" element={<GroupPage />} />
            {/* connect/frn-team/team-building/via */}
            <Route path="/connect/:team/:science" element={<TeamBuilding />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gratitude-science" element={<Science />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            {/* logout */}
            <Route path="/logout" element={<Logout />} />
            <Route path="/*" element={<Home />} />
            <Route path="/*/*" element={<Home />} />
            <Route path="/*/*/*" element={<Home />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </UserProvider>
  );
}

export default App;
