//
import React from "react";

const CardGrid = ({ cards }) => {
  return (
    <div className="container">
      <div className="d-flex row">
        {cards.map((card, i) => (
          <div
            className="col-12 col-md-3 d-flex justify-content-center border-none mt-2"
            key={`${card.title}${i}`}
          >
            <div className="card mx-auto" style={{ width: "18rem" }}>
              <img
                className="card-img-top"
                src={card.image}
                alt="heart icon"
              ></img>
              {/* <div className="card mx-auto" style={{ width: "18rem" }}> */}
              <div className="card-body">
                <h5 className="card-title color-dark-green">{card.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {card.subtitle}
                </h6>
                <div
                  className="card-text color-dark-green"
                  style={{
                    fontSize: "1rem",
                    height: "96px",
                    overflow: "scroll",
                    borderBottom: "1px solid #005F6B",
                    paddingBottom: "6px",
                    paddingTop: "6px",
                    lineHeight: "1.1rem",
                    marginBottom: "6px",
                  }}
                >
                  {card.description}
                </div>
                <a href={card.url} className="card-link color-light-green mt-3">
                  {card.url}
                </a>
              </div>
              {/* </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
