const axios = require('axios').default;

const iconSize = '2em';

const request = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT
});

request.interceptors.request.use(async (config) => {
  const setContentTypeHeader = () => {
    if (config.url === '/buckets' && config.method === 'post') {
      config.headers['Content-Type'] = 'application/application/x-www-form-urlencoded';
    } else if (config.url === '/groups' && config.method === 'post') {
      config.headers['Content-Type'] = 'application/application/x-www-form-urlencoded';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
  };

  config.headers['authorization'] = localStorage.getItem('token');
  config.headers['api-version'] = '1.0.0';
  setContentTypeHeader();
  return config;
});

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

const getBucket = async (bucketID) => {
  try {
    const response = await request.get(`/buckets/${bucketID}`);
    const responseData = await response.data;
    console.log('get bucket by id response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const searchBuckets = async (query = '') => {
  try {
    const response = await request.get('/buckets/search', {params: {query}});
    const responseData = await response.data;
    console.log('search bucket response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const userProfile = async () => {
  try {
    const response = await request.get('/users/userprofile');
    const responseData = await response.data;
    console.log('user profile response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const userLogin = async (email, password) => {
  try {
    const response = await request.post('/users/login', {email, password});
    const responseData = await response.data;
    console.log('user login response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createUser = async (email, password, username, image, affiliation) => {
  try {
    const response = await request.post('/users', {email, password, username, image, affiliation});
    const responseData = await response.data;
    console.log('create user/Register response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createBucket = async (name, email, file) => {
  try {
    const bucketFormData = new FormData();
    bucketFormData.append('image', file);
    bucketFormData.append('email', email);
    bucketFormData.append('name', name);
    const response = await request.post('/buckets', bucketFormData);
    const responseData = await response.data;
    console.log('create bucket response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createGratitude = async (gratitudeBucketId, gratitudeText, strengths) => {
  try {
    const response = await request.post('/gratitudes', {gratitudeBucketId, gratitudeText, strengths});
    const responseData = await response.data;
    console.log('create gratitude response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const redeemCredit = async (bucketID) => {
  try {
    const response = await request.put(`/buckets/${bucketID}/credit`, {});
    const responseData = await response.data;
    console.log('redeem credit response ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getQuestionProtocol = async (noOfQuestions) => {
  try {
    const response = await request.get(`/questions/random/${noOfQuestions}`);
    const responseData = await response.data;
    console.log('getting random questions ', responseData);
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const groupApi = {
  create: async (name, file) => {
    try {
      const bucketFormData = new FormData();
      bucketFormData.append('image', file);
      bucketFormData.append('name', name);
      const response = await request.post('/groups', bucketFormData);
      const responseData = await response.data;
      console.log('create bucket response ', responseData);
      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getById: async (groupId) => {
    try {
      const response = await request.get(`/groups/${groupId}`);
      const responseData = await response.data;
      console.log('create a group response ', responseData);
      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getStatsById: async (groupId) => {
    try {
      const response = await request.get(`/groups/stats/${groupId}`);
      const responseData = await response.data;
      console.log('stats response ', responseData);
      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  members: {
    add: async (groupId, bucketId) => {
      try {
        const response = await request.post(`/groups/members`, {groupId, bucketId});
        const responseData = await response.data;
        console.log('adding a group member ', responseData);
        return responseData;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    remove: async (groupId, bucketId) => {
      try {
        const response = await request.delete(`/groups/members`, {groupId, bucketId});
        const responseData = await response.data;
        console.log('getting random questions ', responseData);
        return responseData;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }
};

export {
  iconSize,
  getWindowSize,
  getBucket,
  searchBuckets,
  createUser,
  createBucket,
  createGratitude,
  userLogin,
  userProfile,
  redeemCredit,
  getQuestionProtocol,
  groupApi
};
