import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { getWindowSize } from "../../lib/serviceCalls";
import SocialIcons from "../SocialIcons/index";
const backgroundImage = "/images/backgrounds/space background.png";
const desktopStyle = {
  background: {
    width: 1024,
    maxWidth: "100%",
    marginTop: 160,
    height: 1024,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",

    // height: 600,
  },
  text: {
    width: "300px",
    position: "absolute",
    top: "50%",
    marginTop: "-150px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "white",
    padding: 20,
    opacity: 0.8,
    zIndex: 1,
    border: "4px solid #081E27",
    borderRadius: 20,
  },
  divPadding: {
    height: 1020,
  },
};
const mobileStyle = {
  background: {
    width: "100%",
    maxWidth: "100%",

    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  text: {
    width: "300px",
    height: 150,
    position: "absolute",
    top: "50px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    opacity: 0.7,
    zIndex: 1,
  },
  divPadding: {
    height: "100vh",
    backgroundColor: "#081E27",
  },
};
const BackgroundCentered = ({ image = backgroundImage }) => {
  const { width } = getWindowSize();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // send the name and email to the server or perform other desired actions
  };

  return (
    <div
      style={width > 1020 ? desktopStyle.background : mobileStyle.background}
    >
      <Image
        src={image}
        fluid
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          border: "10px solid #081E27",
          borderRadius: 20,
        }}
      />
      <div style={width > 1020 ? desktopStyle.text : mobileStyle.text}>
        <h4 className="color-med-green">
          Reach out to us: info@mindfullabs.com
        </h4>
        <SocialIcons
          linkedinUsername="zackp-rager"
          instagramUsername="positivelyzack"
          twitterUsername="zackprager"
        />
      </div>
      <div
        style={width > 1020 ? desktopStyle.divPadding : mobileStyle.divPadding}
      ></div>
    </div>
  );
};

export default BackgroundCentered;
