import React from "react";
import { Container, Row, Col } from "reactstrap";
const Team = () => {
  return (
    <Container className="page">
      <Row>
        <Col>
          <div>The Team</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Team;
