export const gratitudeResearch = [
  {
    title: "The Benefits of Gratitude: A Review of the Empirical Literature",
    subtitle: "Psychological Bulletin, Vol. 135, No. 2, pages 245-268",
    description:
      "This review paper examines the empirical literature on the benefits of gratitude and concludes that gratitude is a positive emotion that is associated with a range of beneficial outcomes, including increased well-being, improved physical health, and better social relationships.",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3370421/",
    image: "./images/single icons/science icon 1.png",
  },
  {
    title: "Gratitude and Well-Being: A Review and Theoretical Integration",
    subtitle: "Clinical Psychology Review, Vol. 31, No. 6, pages 890-905",
    description:
      "This review paper discusses the relationship between gratitude and well-being and presents a theoretical model of how gratitude may affect well-being. The authors suggest that gratitude may lead to increased well-being through a variety of mechanisms, including increased positive emotions, improved social connections, and increased feelings of control and self-determination.",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4434362/",
    image: "./images/single icons/science icon 2.png",
  },
  {
    title:
      "Gratitude and the Reduction of Aggression and Depressive Symptoms in Adolescents",
    subtitle:
      "Journal of Child and Family Studies, Vol. 23, No. 8, pages 1614-1624",
    description:
      "This study examined the effects of gratitude interventions on aggression and depressive symptoms in adolescents. The results showed that gratitude interventions were associated with significant reductions in aggression and depressive symptoms, suggesting that gratitude may be an effective tool for improving mental health in adolescents.",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4138497/",
    image: "./images/single icons/science icon 3.png",
  },
  {
    title:
      "Gratitude Interventions Improve Psychological and Physical Health: A Randomized Controlled Trial",
    subtitle: "Journal of Clinical Psychology, Vol. 64, No. 9, pages 945-955",
    description:
      "This randomized controlled trial tested the effects of a gratitude intervention on psychological and physical health outcomes. The results showed that the gratitude intervention was associated with significant improvements in well-being, happiness, and physical health outcomes, such as reduced inflammation and improved sleep quality.",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5691627/",
    image: "./images/single icons/science icon 4.png",
  },
];
