export const icons = ({ max = 6 }) => {
  //array of user-icon-1.png, user-icon-2.png, user-icon-3.png, user-icon-4.png, user-icon-5.png
  const min = 0;
  const num = Math.floor(Math.random() * (max - min + 1)) + min;

  const iconsArray = [
    "/images/single%20icons/user-icon-1.png",
    "/images/single%20icons/user-icon-2.png",
    "/images/single%20icons/user-icon-3.png",
    "/images/single%20icons/user-icon-4.png",
    "/images/single%20icons/user-icon-5.png",
    "/images/single%20icons/user-icon-6.png",
  ];
  console.log("index", num, iconsArray[num]);
  return iconsArray[num];
};

export const iconsArray = [
  "/images/single%20icons/user-icon-1.png",
  "/images/single%20icons/user-icon-2.png",
  "/images/single%20icons/user-icon-3.png",
  "/images/single%20icons/user-icon-4.png",
  "/images/single%20icons/user-icon-5.png",
  "/images/single%20icons/user-icon-6.png",
];
