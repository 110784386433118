import React, {useContext} from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserProvider';
const NavigationBar = () => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const handleNav = (route) => {
    navigate(route);
  };
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand onClick={() => handleNav('/')} href="#" className="pl-2">
        Gratitude Bucket (2.0)
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => handleNav('/')} href="">
            Home
          </Nav.Link>
          <Nav.Link onClick={() => handleNav('/create')} href="">
            Create A Bucket
          </Nav.Link>
          <Nav.Link onClick={() => handleNav('/search')} href="">
            Search
          </Nav.Link>
          <NavDropdown title="About" id="collasible-nav-dropdown">
            <NavDropdown.Item onClick={() => handleNav('/about')} href="">
              Team
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNav('/contact')} href="">
              Contact
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNav('/contact')} href="">
              Work With Us
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {/* <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item> */}
          </NavDropdown>
        </Nav>
        {user && user.username ? (
          <Nav>
            <Nav.Link href="/user">Profile</Nav.Link>
          </Nav>
        ) : (
          ''
        )}
        {user && user.username ? (
          <Nav>
            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        ) : (
          <Nav>
            <Nav.Link href="/login">Login</Nav.Link>
          </Nav>
        )}
        {user && user.username ? (
          ''
        ) : (
          <Nav>
            <Nav.Link href="/register">Register</Nav.Link>
          </Nav>
        )}

        <Nav>
          <Nav.Link eventKey={2} onClick={() => handleNav('/user')} href="">
            {/* <FaUserCircle size={iconSize} color={styles.medBlue} />, */}
            <img src="/images/single icons/user-icon-4.png" width={50} />
          </Nav.Link>
        </Nav>
        <div
          className="d-md-block d-none color-light-green"
          style={{
            position: 'absolute',
            right: 0,
            top: 90,
            marginRight: 20,
            zIndex: 100
          }}
        >
          {user && user.email ? `Logged in as: ${user.email} ` : ''}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default React.memo(NavigationBar);
