import React, {useContext, useEffect, useState} from 'react';
import {Container} from 'reactstrap';
import {UserContext} from '../../context/UserProvider';
import {useLocation, useNavigate} from 'react-router-dom';

import LogoCentered from '../../components/LogoCentered';
import {InfinitySpin} from 'react-loader-spinner';

const Login = () => {
  const {user, login, logout} = useContext(UserContext);
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const message = queryParameters.get('message');
  const redirect = queryParameters.get('redirect');
  useEffect(() => {
    if (message) {
      setUserMessage(message);
    }
  }, []);

  useEffect(() => {
    if (redirect && user && user.username) {
      navigate(`/${redirect}`);
    } else if (user && user.username) navigate('/');
  }, [user]);

  const handleLogin = async (event) => {
    try {
      event.preventDefault();
      setUserMessage('');
      setLoading(true);
      const {email, password} = event.target.elements;
      let username = email; //data massaging for the backend
      const response = await login(username.value, password.value);
      if (!response) {
        setUserMessage('Invalid username or password');
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Container className="page">
        {/* create a vertically centered div */}
        <LogoCentered />
        {!loading ? (
          <div className="centered">
            {/* <div
            style={{
              height: 280,
              position: "relative",

              marginTop: 30,
              marginLeft: "auto",
              marginRight: "auto",
              width: 320,

              borderRadius: 10,
              opacity: 0.8,
            }}
            className="border"
          >
            <div
              style={{
                width: 320,
                height: 270,
                backgroundColor: "white",
                borderRadius: 10,
              }}
            >
              <img
                style={{ width: "100%" }}
                src="./images/dark_logo_transparent_background.png"
              />
              <div>Welcome back! Login here.</div>
            </div>
            {/* <h1 style={{ fontSize: 80, color: "black" }}>Gratitude Bucket</h1> */}
            {/* </div> */}
            {message ? (
              <h4 style={{color: '#005F6B'}}>{message}</h4>
            ) : (
              <h5>Welcome back! It is good to see you again! </h5>
            )}
            <div>
              {userMessage ? (
                <div className="alert alert-danger" role="alert">
                  {userMessage ? userMessage : ''}
                </div>
              ) : (
                ''
              )}
              {user ? (
                <button onClick={logout}>Logout</button>
              ) : (
                <form onSubmit={handleLogin} method="POST" autoComplete="on">
                  <input className="mt-2" name="email" type="email" placeholder="email" autoComplete="on" />
                  <input className="mt-2" name="password" type="password" placeholder="password" autoComplete="on" />
                  <input
                    className="mt-2"
                    style={{background: 'rgb(33, 163, 163)'}}
                    type="submit"
                    value={user ? 'Logout' : 'Login'}
                  />
                </form>
              )}
            </div>
          </div>
        ) : (
          <div>
            <InfinitySpin />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Login;
