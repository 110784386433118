//write a function that returns the JSx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { backgroundStyle } from "../../styles";
import TeamMembers from "../../components/TeamMembers/index";
import YoutubeEmbed from "../../components/YouTube";
import LogoCentered from "../../components/LogoCentered";
const backgroundImg = "./images/background-search-page.png";
const backgroundStyle2 = {
  height: 400,
  backgroundImage: `url(${backgroundImg})`,
  opacity: 0.9,
};

const About = () => {
  return (
    <div>
      <div className="page mb-0 pb-0">
        <div
          style={{
            ...backgroundStyle.container,
            ...backgroundStyle2,
            ...{ borderBottom: "4px solid #47725E" },
          }}
        >
          <LogoCentered />
        </div>

        <div
          style={{
            width: 440,
            maxWidth: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="row justify-content-center"
        ></div>
        <div className="pb-5 pt-2 page">
          <div className="col-md-10 offset-md-1 p-3">
            <h1 style={{ color: "#005F6B", border: "0 solid red" }}>
              About Us
            </h1>

            <div
              className="paragraph"
              style={{ fontSize: "1.4rem", color: "#005F6B" }}
            >
              We work on building user experiences that make people happier and
              healthier in a field called{" "}
              <a href="https://www.mindfullabs.com/post/positive-humane-technology">
                Humane Technology or Positive Technology
              </a>
              . We use the latest research in the science of well-being (
              <a
                href="https://ppc.sas.upenn.edu/"
                alt="positive psychology center at UPenn"
              >
                Positive Psychology
              </a>
              ) to design our applications.
            </div>
            <div className="centered">
              <div
                style={{
                  height: 430,
                  position: "relative",
                  maxWidth: "100%",
                  paddingTop: 10,
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: 420,

                  borderRadius: 10,
                  // opacity: 0.8,
                }}
                // className="border"
              >
                <div
                  style={{
                    width: 420,
                    maxWidth: "100%",
                    height: 360,
                    // backgroundColor: "white",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "5px solid #47725E",
                    }}
                    src="/images/backgrounds/humane tech.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="paragraph mt-2"
              style={{ fontSize: "1.4rem", color: "#005F6B" }}
            >
              <strong>Why we picked gratitude? </strong>
              <br />
              Practicing gratitude has been shown to have a number of positive
              effects on mental and physical well-being. Studies have found that
              people who regularly practice gratitude have higher levels of
              positive emotions, life satisfaction, and well-being. They also
              have lower levels of depression, stress, and anxiety.
            </div>
            <div
              className="paragraph mt-5"
              style={{ fontSize: "1.5rem", color: "#005F6B" }}
            ></div>
            <div
              className="paragraph mt-5"
              style={{ fontSize: "1.5rem", color: "#005F6B" }}
            >
              <h2 style={{ color: "#005F6B", border: "0 solid red" }}>
                The Team
              </h2>
            </div>
          </div>
          <TeamMembers />
          <div
            className="paragraph mt-5"
            style={{ fontSize: "1rem", color: "#005F6B" }}
          >
            Learn more about Positive Technology <br />- Zack Prager at Web2day
            (Nantes, France)
            <YoutubeEmbed embedId={"_DTXu4m_lak?t=1441"} />
          </div>
          <div
            className="paragraph mt-5"
            style={{ fontSize: "1rem", color: "#005F6B" }}
          >
            Learn more about Human Technology on 60 minutes:
            <YoutubeEmbed embedId={"awAMTQZmvPE"} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
