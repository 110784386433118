import React from "react";
import { Container, Row, Col } from "reactstrap";

const Science = () => {
  return (
    <Container className="page">
      <Row>
        <Col>
          <div>The Science</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Science;
