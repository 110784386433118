import React, {useContext, useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';
import BackgroundLayout from './components/BackgroundLayout';
import {UserContext} from '../../context/UserProvider';
import Footer from '../../components/Footer';

import {useNavigate} from 'react-router-dom';
const UserProfile = () => {
  const navigate = useNavigate();
  const defaultProfilePic = '/images/single icons/user-icon-4.png';
  const {user} = useContext(UserContext);

  useEffect(() => {
    if (!user || !user.username) {
      return navigate('/login?message=Please login to view your profile.');
    }
  }, []);
  return (
    <div>
      <Container>
        {user ? (
          <BackgroundLayout
            image={'/images/backgrounds/profile v4.jpeg'}
            userProfilePic={
              user && user.bucket && user.bucket.bucketImage ? user.bucket.bucketImage : defaultProfilePic
            }
            user={user}
          />
        ) : (
          ''
        )}
      </Container>
    </div>
  );
};

export default UserProfile;
