import React, {useState, useEffect} from 'react';
import {Button, Container, Row, Col} from 'reactstrap';
import './styles.css';
import ImageComponent from '../../components/ImageComponent/index';
import { getQuestionProtocol } from '../../lib/serviceCalls';

const teamMembersData = [
  {
    name: 'megan',
    username: 'megan',
    id: '123'
  },
  {
    name: 'zack',
    username: 'zack',
    id: '124'
  },
  {
    name: 'tala',
    username: 'tala',
    id: '125'
  },
  {
    name: 'heather',
    username: 'heather',
    id: '126'
  }
];
const questionsData = [
  {
    Text: 'Who is the most visionary and forward-thinking person in this group?',
    Strength: 'Futuristic',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Envisions exciting possibilities for the future'
  },
  {
    Text: 'Who is the best at creating a plan to achieve a goal in this group?',
    Strength: 'Strategic',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Creates a plan to achieve a goal'
  },
  {
    Text: 'Who is the best at generating new and creative ideas in this group?',
    Strength: 'Ideation',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Generates new and creative ideas'
  },
  {
    Text: 'Who is the best at collecting and seeking information from various sources in this group?',
    Strength: 'Input',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Collects and seeks information from various sources'
  },
  {
    Text: 'Who is the best at seeking new learning experiences and constantly improving in this group?',
    Strength: 'Learner',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Seeks new learning experiences and constantly improves'
  },
  {
    Text: 'Who is the best at analyzing information to understand complex problems in this group?',
    Strength: 'Analytical',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Analyzes information to understand complex problems'
  },
  {
    Text: 'Who is the best at understanding and considering the circumstances and events surrounding a situation in this group?',
    Strength: 'Context',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Understands and considers the circumstances and events surrounding a situation'
  },
  {
    Text: 'Who is the best at seeing patterns and relationships in complex information in this group?',
    Strength: 'Systems',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Sees patterns and relationships in complex information'
  },
  {
    Text: 'Who is the best at winning others over to their ideas in this group?',
    Strength: 'Woo',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Wins others over to their ideas'
  },
  {
    Text: 'Who is the best at effectively expressing thoughts and ideas to others in this group?',
    Strength: 'Communication',
    Category: 'Team Building',
    ScienceCategory: 'Strengths Finder',
    Live: true,
    Description: 'Effectively expresses thoughts and ideas to others'
  }
];
const TeamBuilding = () => {
  const path = window.location.pathname;
  console.log('Path:', path);
  const team = path.split('/')[2];
  const scienceProtocol = path.split('/')[3];
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [animationClass, setAnimationClass] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const NUM_OF_QUESTIONS = 5;
  useEffect(() => {
    //get the questions for this protocol
    const asyncFn = async (NUM_OF_QUESTIONS) => {
      try {
        const res = await getQuestionProtocol(NUM_OF_QUESTIONS);
        setQuestions(res);
        setCurrentQuestion(res[0].Text);
        //get the team members for this team, may need to limit this pull just in case there are big teams (but not likely)
        setTeamMembers(
          teamMembersData.map((member) => {
            member.selected = false;
            return member;
          })
        );
      }
      catch (error) {
        console.log('error', error);
      }
    }
    asyncFn();
  }, []);

  useEffect(() => {
    if (!currentStep) return; //first step
    setAnimationClass('fade-out');
    setTimeout(() => {
      setCurrentQuestion(questions[currentStep].Text);
      setAnimationClass('fade-in');
    }, 800);
  }, [currentStep]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentStep(currentStep + 1);
  };
  const handleSelection = ({id}) => {
    setTeamMembers(teamMembers.map((member) => (member.id === id ? {...member, selected: !member.selected} : member)));
    setCurrentStep(currentStep + 1);
  };
  const buttonStyle = {background: 'rgb(33, 163, 163)', width: '80%', fontSize: '2rem', marginTop: '15px'};
  const buttonSelectedStyle = {
    background: 'rgb(33, 163, 163)',
    width: '80%',
    fontSize: '2rem',
    marginTop: '15px',
    border: '3px solid white'
  };
  return (
    <div
      className="d-flex align-items-center backgroundGreen"
      style={{height: '80vh', width: '100%', backgroundColor: 'rgb(8, 30, 39)'}}
    >
      {/* {questions && questions.length && questions[currentStep].Text} */}
      {/* <div className="d-flex align-items-center border" style={{height: '100%'}}> */}
      <div className="mx-auto backgroundGreen" style={{width: '100%', backgroundColor: 'rgb(8, 30, 39)'}}>
        <div style={{width: '100%'}}>
          <form onSubmit={handleSubmit} style={{width: '100%', minWidth: '70%'}}>
            <div className={animationClass} style={{height: '450px', width: '100%'}}>
              {currentQuestion ? (
                <ImageComponent img={`/images/single icons/${questions[currentStep].Strength}.png`} />
              ) : (
                ''
              )}
              <h3 style={{color: 'white'}}>{currentQuestion ? currentQuestion : 'loading..'}</h3>
              {/* stack columns */}
              <div className="border">
                {teamMembers
                  ? teamMembers.map((member) => (
                      <div style={{width: '50%', height: 70}} className="mx-auto">
                        {/* <input
                          className="mt-2"
                          style={{background: 'rgb(33, 163, 163)'}}
                          type="buttons"
                          value={member.username ? member.username : ''}
                        /> */}
                        <Button
                          onClick={() => {
                            handleSelection(member.id);
                          }}
                          style={member.selected ? buttonSelectedStyle : buttonStyle}
                        >
                          {member.username}
                        </Button>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default TeamBuilding;
