import React, {useContext} from 'react';

const StrengthsCount = ({strengthsMap}) => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="text-light">
          <h3>Strengths people have seen in you</h3>
        </div>
        <div className="row text-center color-light-green pb-3 mb-5">
          {Object.keys(strengthsMap).length > 0
            ? Object.keys(strengthsMap).map((strength, index) => {
                return (
                  <div className="col-6 col-sm-4 col-md-3  px-5 text-center ">
                    <h4 className="text-center  my-3  color-light-green">
                      {strength} <span style={{fontSize: 16}}>x</span> {strengthsMap[strength]}
                    </h4>
                    <div
                      style={{
                        width: '100%',
                        // height: 120,
                        margin: 'auto',
                        display: 'block'
                      }}
                    >
                      <img style={{width: '100%'}} src={`/images/single icons/${strength}.png`} alt={strength} />
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StrengthsCount;
